import { Route, Switch } from 'react-router-dom';
import AboutMe from '../../ColumnTwo/Routes/AboutMe/AboutMe';
import Projects from '../../ColumnTwo/Routes/Projects/Projects';
import ContactForm from '../../ColumnTwo/Routes/ContactForm/ContactForm';
import LandingPage from '../../ColumnTwo/Routes/LandingPage/LandingPage';
import Blog from '../../ColumnTwo/Routes/Blog/Blog.js';

const Routes = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/'} component={LandingPage}></Route>
        <Route path={'/projects'} component={Projects}></Route>
        <Route path={'/contact'} component={ContactForm}></Route>
        {/* <Route path={'/blog'} component={Blog}></Route> */}
        <Route path={'/about'} component={AboutMe}></Route>
      </Switch>
    </>
  );
};
export default Routes;
