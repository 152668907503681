import { Link } from 'react-router-dom';
function AboutMe() {
  return (
    <section className="about__me">
      <h2 className="about__header">About Me</h2>
      <div className="about__me__content">
        <p className="about__me__text">
          Hello! I'm a passionate Software Engineer motivated by the ability to
          make a difference in peoples' lives. I enjoy solving problems using
          code, and challenging myself while learning new technologies.
        </p>
        <p className="about__me__text">
          Prior to my work as a Software Engineer, I worked for 4 years as a
          Board Certified Behavior Analyst (BCBA), working together with
          individuals, therapists, and families to make meaningful positive
          changes to improve their lives through use of assessment, analysis of
          data, and implementation of evidence-based approaches. I have
          consistently worked towards improving myself, growing professionally,
          and doing everything possible to have a positive impact on the people
          who I have worked with.
        </p>
        <p>
          Besides coding I enjoy trying new restaurants, traveling, skiing,
          boating, discovering (and playing) new music, yoga, gaming, puzzles,
          crossword puzzles, and hiking!
        </p>
        <p className="about__me__text">
          Since entering the field as a Software Engineer, I have been actively
          engaged in learning and implementing what have learned. I have
          developed a passion for development and coding, and am motivated by
          taking on challenges in a head-on manner. I look forward to working as
          part of a team to make meaningful and productive contributions, as
          well as to further improve on my skills as a developer.
        </p>
        <p className="about__me__text">
          Let's connect! Feel free to reach out to me using the{' '}
          <Link to="/contact" className="about__link__styling">
            contact form
          </Link>{' '}
          or via{' '}
          <a
            className="about__link__styling"
            href="https://linkedin.com/in/tucker-gilligan"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn.
          </a>
        </p>
      </div>
    </section>
  );
}

export default AboutMe;
