import '../ColumnOne.css';
import { Link } from 'react-router-dom';
const Header = () => {
  return (
    <div className='header__main'>
      <div className='side__bar'>
        {/* <hr /> */}
        <Link to='/' className='header__link'>
          <h1 className='header__title'>
            <span className='header__word1'>TUCKER</span>
            <span className='header__word2'>GILLIGAN</span>
            <span className='header__word3'>WEB</span>
            <span className='header__word4'>DEVELOPER</span>
          </h1>
        </Link>
      </div>
    </div>
  );
};

export default Header;
