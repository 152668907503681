import GenerateButton from './GenerateButton';
const ComplimentGenerator = () => {
  return (
    <div className="project__summary">
      {/* <div className="project__summary__item"> */}
      <div className="title__stack">
        <h3 className="project__title">Complimented</h3>
        <h4 className="project__stack">
          JavaScript | React | Node.js | PostgreSQL
        </h4>
      </div>
      <p className="project__description">
        Complimented is a Full-Stack web application developed to practice using
        React Hooks, and developing Express APIs using NodeJS and PostgreSQL
        database management. The purpose of this app is to spread good-vibes to
        others by providing randomized compliments. Send me an email with a good
        compliment to put in the database!
      </p>
      <GenerateButton />
      <div className="repo__links">
        <a
          href="https://complimented.vercel.app"
          className="project__link"
          target="_blank"
          rel="noreferrer"
        >
          Live Deployment
        </a>
        <a
          href="https://github.com/Tucker-Gilligan/compliment-generator"
          className="project__link"
          target="_blank"
          rel="noreferrer"
        >
          Client Repo
        </a>
        <a
          href="https://github.com/Tucker-Gilligan/compliment-generator-server"
          className="project__link"
          target="_blank"
          rel="noreferrer"
        >
          Server Repo
        </a>
      </div>
    </div>
  );
};

export default ComplimentGenerator;
