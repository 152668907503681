import './App.css';
import Routes from './Router/Routes';
import { withRouter } from 'react-router-dom';
import Header from '../ColumnOne/Header/Header';
import Nav from '../ColumnOne/Nav/Nav';
import MyMedia from '../ColumnOne/MyMedia/MyMedia';
// import AboutMe from '../ColumnOne/AboutMe/AboutMe';

const App = ({ location }) => {
  return (
    <div className="App">
      <div className="col__1">
        <hr className="top__hr" />
        <div className="media__header__nav">
          <div className="media__header">
            <Header />
            <MyMedia />
          </div>
          <Nav />
        </div>
      </div>

      {/* <AboutMe /> */}
      <div className="col__2">
        <hr className="top__hr" />
        <Routes />
      </div>
    </div>
  );
};

export default withRouter(App);
