const QuizApp = () => {
  return (
    <div className="project__summary">
      <div className="title__stack">
        <h3 className="project__title">Schitt's Creek Quiz</h3>
        <h4 className="project__stack">JavaScript | jQuery | HTML | CSS</h4>
      </div>
      <p className="project__description">
        Front-end quiz application based on the TV show Schitt's Creek! Users
        will receive feedback and track a score throughout the quiz, and are
        able to re-take the quiz once they reach the end. Feel free to give it a
        try with the live deployment linked above.
      </p>
      <div className="repo__links">
        <a
          href="https://tucker-gilligan.github.io/schitts-creek-quiz/"
          className="project__link"
          target="_blank"
          rel="noreferrer"
        >
          Live Deployment
        </a>
        <a
          href="https://github.com/Tucker-Gilligan/schitts-creek-quiz"
          className="project__link"
          target="_blank"
          rel="noreferrer"
        >
          GitHub Repo
        </a>
      </div>
    </div>
  );
};

export default QuizApp;
