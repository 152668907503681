import { ValidationError, useForm } from '@formspree/react';
import '../ColumnTwo.css';
function ContactForm() {
  const [state, handleSubmit] = useForm('contact');
  if (state.succeeded) {
    return <div className="confirmation">Your email has been sent!</div>;
  }
  return (
    <div className="contact__page">
      <h2 className="contact__title">Contact</h2>
      <div className="form__container">
        <form onSubmit={handleSubmit} className="contactForm">
          <label htmlFor="email" className="contact__label">
            Email:
          </label>
          <input
            id="email"
            type="email"
            name="email"
            placeholder="youremail@email.com"
            className="contact__input"
            required
          />
          <label htmlFor="message" className="contact__label">
            Message:
          </label>
          <textarea
            id="text"
            type="text"
            name="text"
            placeholder="Your message..."
            className="contact__message"
            required
          />
          <ValidationError field="email" prefix="Email" errors={state.errors} />
          <button
            type="submit"
            disabled={state.submitting}
            className="contact__submit"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
