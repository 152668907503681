import { Link } from 'react-router-dom';
import '../ColumnOne.css';
function Nav() {
  return (
    <div className="nav">
      <ul className="header__nav">
        <li className="nav__item">
          <Link to="/projects" className="nav__text">
            Projects
          </Link>
        </li>
        <li className="nav__item">
          <Link to="/contact" className="nav__text">
            Contact
          </Link>
        </li>
        <li className="nav__item">
          <Link to="/about" className="nav__text">
            About
          </Link>
        </li>
        {/* <li className="nav__item">
          <Link to="/blog" className="nav__text">
            Blog
          </Link>
        </li> */}
      </ul>
    </div>
  );
}

export default Nav;
