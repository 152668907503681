import '../ColumnOne.css';
import GithubLogo from './GithubLogo.png';
import LinkedInLogo from './LinkedInLogo.png';
// import email from './email.png';
import Codewars from './codewars-logo.png';
import { Link } from 'react-router-dom';
function MyMedia() {
  return (
    <ul className="media__list">
      <li className="media__item">
        <a
          href="https://www.linkedin.com/in/tucker-gilligan/"
          target="_blank"
          rel="noopener noreferrer"
          className="media__link"
        >
          <img src={LinkedInLogo} alt="LinkedIn" width="40px" />
          <p className="imageLabel linkedinLabel">LinkedIn</p>
        </a>
      </li>

      <li className="media__item">
        <a
          rel="noopener noreferrer"
          href="https://github.com/Tucker-Gilligan"
          target="_blank"
          className="media__link"
        >
          <img src={GithubLogo} alt="GitHub" width="40px" />
          <p className="imageLabel githubLabel">GitHub</p>
        </a>
      </li>
      <li className="media__item">
        <a
          rel="noopener noreferrer"
          href="https://www.codewars.com/users/gillitux"
          target="_blank"
          className="media__link"
        >
          <img src={Codewars} alt="Codewars" width="40px" />
          <p className="imageLabel codewarsLabel">Codewars</p>
        </a>
      </li>
    </ul>
  );
}

export default MyMedia;
