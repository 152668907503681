import '../ColumnTwo.css';
function LandingPage() {
  return (
    <div className="landing__page">
      <h2 className="landing__header">Welcome & Greetings!</h2>
      <p className="landing__blurb">
        My name is Tucker - I am a Full Stack Web Developer with experience
        using JavaScript, React.js, Node.js, and PostgreSQL. I am enthusiastic
        about creating dynamic web applications, solving problems using code,
        and learning to use new technologies!
      </p>
    </div>
  );
}

export default LandingPage;
