const Barberism = () => {
  return (
    <div className="project__summary">
      <div className="title__stack">
        <h3 className="project__title">Barberism</h3>
        <h4 className="project__stack">
          JavaScript | React | Node.js | PostgreSQL
        </h4>
      </div>
      <p className="project__description">
        Barberism is a Full-Stack web application developed to connect people
        with Autism or sensory sensitivies with qualified barbers who are
        willing to meet their individualized needs. This project was developed
        as my first full-stack capstone project while attending Thinkful.
      </p>
      <div className="backlog__container">
        <h4 className="backlog__header">
          Future plans to improve to this application include:
        </h4>
        <ul className="project__backlog">
          <li className="project__backlog__item">
            Add user registration/login option for barbers and clients
          </li>
          <li className="project__backlog__item">
            Evaluate styling - add hamburger menu to improve use on mobile
            devices
          </li>
          <li className="project__backlog__item">
            Delete, edit, and profile-registration features are available only
            to Admin or registered user.
          </li>
          <li className="project__backlog__item">
            Incorporate Google Maps API for more accurate/targeted locations
          </li>
          <li className="project__backlog__item">
            Allow barbers to input their services
          </li>
        </ul>
      </div>

      <div className="repo__links">
        <a
          href="https://barberism.vercel.app"
          className="project__link"
          target="_blank"
          rel="noreferrer"
        >
          Live Deployment
        </a>
        <a
          href="https://github.com/Tucker-Gilligan/barberism"
          className="project__link"
          target="_blank"
          rel="noreferrer"
        >
          Client Repo
        </a>
        <a
          href="https://github.com/Tucker-Gilligan/barberism-api"
          className="project__link"
          target="_blank"
          rel="noreferrer"
        >
          Server Repo
        </a>
      </div>
    </div>
  );
};

export default Barberism;
