import './Projects.css';
import Barberism from './Barberism';
import ComplimentGenerator from './Complimented/ComplimentGenerator.js';
import QuizApp from './QuizApp';
const Projects = () => {
  return (
    <div className="projects">
      <h2 className="projects__header">Projects</h2>
      <div className="project__summary">
        <section className="project__summary__item">
          <Barberism />
        </section>
        <section className="project__summary__item">
          <ComplimentGenerator />
        </section>
        <section className="project__summary__item">
          <QuizApp />
        </section>
      </div>
    </div>
  );
};

export default Projects;
